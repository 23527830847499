import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState, Suspense, useTransition } from 'react';
import { API_URL } from '../../../config/constants';
import './Monitoring.scss';
import CustomLineChart from '../../components/CustomLineChart';
import { CSVLink } from 'react-csv';
import DataListMenu from './DataListMenu';
import MonitTable from './MonitTable';
import csvSaveIcon from '../../../images/csvSave.png';
import { Scrollbars } from 'rc-scrollbars';
import { useBlocker, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import YouWereEditing from '../../components/YouWereEditing';
import editModeIcon from '../../../images/editDS.png';
import Loading from '../../components/Loading';
import ChkDelModal from '../../components/ChkDelModal';

// const MonitTable = React.lazy(() => import('./MonitTable'));

function RealTimeMonitoring(props) {
    const { userIsNormal } = useOutletContext();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const qsName = searchParams.get("name");
    const qsDataId = searchParams.get("dataId");

    const [isPending, startTransition] = useTransition();

    const companyInitial = 'K';
    const sensorTypeInitial = 'D';
    // 추후 계정 정보에서 company 컬럼 데이터 받아오도록 수정要
    // company 정보를 기준으로 rawData 테이블을 선별해 받아옴
    const company = "세동산업";


    // 센서 모니터링 데이터 저장 여부 상태
    const [wholeDatas, setWholeDatas] = useState([]);
    const [curPageData, setCurPageData] = useState({}); //wholeDatas[selSensorIdx]로 대체 가능함. 삭제 요망.
    const [dataStructures, setDataStructures] = useState([]);

    const [lastId, setLastId] = useState(null);
    const [lastData, setLastData] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    // const [tableLoading, setTableLoading] = useState(true);

    // 상단 라디오 버튼 상태
    const [selSensorIdx, setSelSensorIdx] = useState(-1);

    const [selDataIdx, setSelDataIdx] = useState(-1);

    const [formData, setFormData] = useState([]);
    const [changed, setChanged] = useState(false);
    const [chkDelModalIsOpen, setChkDelModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    async function getInitMonit() {
        await axios.post(`${API_URL}/monitoring-manage/getInitMonit`, { company: '세동산업', name: qsName, dataId: qsDataId }).then((res) => {
            const resInitData = res.data;
            const convResData = resInitData["datas"].map(data => {
                const dsData = data.dataStructure;
                const monData = data.monitData;
                const convMonData = monData.map(mon => {
                    const dataStr = mon.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                    const dataArr = dataStr.split(';');
                    dataArr.pop();
                    const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                    return ({ ...mon, data: arrayConvertedDataArr })
                });
                const dataNameArr = dsData.dataName.split(';');
                const dimensionArr = dsData.dimension.split(';');
                return ({
                    ...data,
                    monitData: convMonData,
                    dataStructure: {
                        setName: dsData.setName,
                        noRecords: dsData.noRecords,
                        name: dsData.name,
                        dataName: dataNameArr,
                        dimension: dimensionArr,
                        saveState: dsData.saveState,
                    },
                });
            });
            const dataStructureArr = convResData.map(el => el.dataStructure);
            const lastMdId = convResData[0]["monitData"].length > 0 ? convResData[0]["monitData"][convResData[0]["monitData"].length - 1].dataId : -1;
            if (resInitData["selectedInfo"] === undefined) {
                if (dataStructureArr.length > 0) setSelSensorIdx(0);
                if (convResData[0].monitData.length > 0) startTransition(() => setSelDataIdx(0));
                setDataStructures([...dataStructureArr]);
                setWholeDatas([...convResData]);
                setCurPageData({ ...convResData[0] });
                setLastId(prev => lastMdId);
                setLastData(convResData[0].end);
                setIsLoading(false);
            } else {
                setSelSensorIdx(resInitData["selectedInfo"]["selectedDSIdx"]);
                setSelDataIdx(resInitData["selectedInfo"]["selectedDataIdx"]);
                setDataStructures([...dataStructureArr]);
                setWholeDatas([...convResData]);
                setCurPageData({ ...convResData[resInitData["selectedInfo"]["selectedDSIdx"]] });
                setLastId(prev => convResData[resInitData["selectedInfo"]["selectedDSIdx"]]["monitData"][convResData[resInitData["selectedInfo"]["selectedDSIdx"]]["monitData"].length - 1].dataId);
                setLastData(convResData[resInitData["selectedInfo"]["selectedDSIdx"]].end);
                setIsLoading(false);
            }
        });
    };
    const onChangeSensorType = (e) => {
        e.preventDefault();
        console.log('타입 변경')
        const wdIdx = parseInt(e.target.value);
        const lastMdId = wholeDatas[wdIdx].monitData.length > 0 ? wholeDatas[wdIdx].monitData[wholeDatas[wdIdx].monitData.length - 1].dataId : -1;
        setCurPageData({ ...wholeDatas[wdIdx] });
        setLastData(wholeDatas[wdIdx].end);
        setSelSensorIdx(wdIdx);
        setLastId(lastMdId);
        if (lastMdId >= 0) {
            startTransition(() => setSelDataIdx(0));
        } else {
            startTransition(() => setSelDataIdx(-1));
        }
    };

    const onChangeToggle = async (e, idx) => {
        const copiedDsArr = [...dataStructures];
        await axios.post(`${API_URL}/monitoring-manage/setSaveState`, { setName: copiedDsArr[idx].setName, saveState: !copiedDsArr[idx].saveState }).then(res => {
            copiedDsArr[idx] = { ...copiedDsArr[idx], saveState: res.data.saveState };
            setDataStructures([...copiedDsArr]);
            const copiedWdArr = [...wholeDatas];
            copiedWdArr[idx] = { ...copiedWdArr[idx], dataStructure: { ...copiedWdArr[idx]["dataStructure"], saveState: res.data.saveState } };
            setWholeDatas([...copiedWdArr]);
        }).catch(err => { console.error(err) });
    };

    const onDeleteData = async (e) => {
        e.preventDefault();
        const setName = dataStructures[selSensorIdx]["setName"];
        const dataId = curPageData["monitData"][selDataIdx]["dataId"];
        await axios.post(`${API_URL}/monitoring-manage/deleteMonit`, { setName: setName, dataId: dataId, name: curPageData["dataStructure"]["name"] }).then(res => {
            const { success } = res.data;
            if (success) {
                // wholeDatas에서 해당 데이터 제거
                if (wholeDatas[selSensorIdx]["monitData"].length - 1 === 0) {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    const copiedWData = [...wholeDatas];
                    const modifiedWDMDArr = copiedWData[selSensorIdx]["monitData"].filter(el => el.dataId !== dataId);
                    copiedWData[selDataIdx] = { ...copiedWData[selDataIdx], monitData: modifiedWDMDArr };
                    setWholeDatas([...copiedWData]);
                    setSelDataIdx(-1);
                    setChkDelModalIsOpen(false);
                } else if (selDataIdx === wholeDatas[selSensorIdx]["monitData"].length - 1) {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    const copiedWData = [...wholeDatas];
                    const modifiedWDMDArr = copiedWData[selSensorIdx]["monitData"].filter(el => el.dataId !== dataId);
                    copiedWData[selDataIdx] = { ...copiedWData[selDataIdx], monitData: modifiedWDMDArr };
                    setWholeDatas([...copiedWData]);
                    setSelDataIdx(prev => prev - 1);
                    setChkDelModalIsOpen(false);
                } else {
                    const copiedCPMDArr = [...curPageData["monitData"]];
                    const modifiedCPD = copiedCPMDArr.filter(el => el.dataId !== dataId)
                    setCurPageData({ ...curPageData, ["monitData"]: modifiedCPD });
                    const copiedWData = [...wholeDatas];
                    const modifiedWDMDArr = copiedWData[selSensorIdx]["monitData"].filter(el => el.dataId !== dataId);
                    copiedWData[selDataIdx] = { ...copiedWData[selDataIdx], monitData: modifiedWDMDArr };
                    setWholeDatas([...copiedWData]);
                    setSelDataIdx(prev => prev + 1);
                    setChkDelModalIsOpen(false);
                }
            } else {
                // 시도가 성공하지 않음을 알림
            }
        }).catch(err => console.error(err));
    }

    const onChangeInput = (e, index, key) => {
        const copiedArr = [...formData];
        copiedArr[index][key] = parseFloat(e.target.value);
        setFormData([...copiedArr]);
        setChanged(true);
    }

    const getPrevData = async e => {
        try {
            const res = await axios.post(`${API_URL}/monitoring-manage/getPrevMonit`, {
                headId: curPageData['monitData'][0].dataId, company, isAbout: dataStructures[selSensorIdx]["setName"]
            });
            const monitDatas = res.data.monitData;
            if (monitDatas.length > 0) {
                const convertedData = monitDatas.map(item => {
                    const dataStr = item.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                    const dataArr = dataStr.split(';');
                    dataArr.pop();
                    const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                    return ({
                        ...item,
                        data: arrayConvertedDataArr,
                    });
                })
                // wholeDatas 활용한 방향으로 수정 要
                const copiedArr = [...wholeDatas];
                copiedArr[selSensorIdx]['monitData'] = [...convertedData, ...copiedArr[selSensorIdx]['monitData']];
                setCurPageData(prevObj => ({
                    ...prevObj,
                    monitData: [...copiedArr[selSensorIdx]["monitData"]]
                }));
                setWholeDatas([...copiedArr]);
                setLastData(prev => res.data.end ?? prev);
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getData = useCallback(async (isAbout) => {
        console.log('겟 데이터 실행')
        // setPageLoading(true);
        try {
            const res = await axios.post(`${API_URL}/monitoring-manage/getMonit`, { lastId: lastId, company: company, isAbout: isAbout });
            const monitDatas = res.data.monitData;
            // monitDatas = [..., { dataId, dDsId, data, date }]
            const convertedData = monitDatas.map(item => {
                const dataStr = item.data; // '...; 0.0, 0.0221; 0.1, 0.2311;' 형태의 센서 데이터
                const dataArr = dataStr.split(';');
                dataArr.pop();
                const arrayConvertedDataArr = dataArr.map(el => el.split(','));
                return ({
                    ...item,
                    data: arrayConvertedDataArr,
                });
            })
            // wholeDatas 활용한 방향으로 수정 要
            const copiedArr = [...wholeDatas];
            copiedArr[selSensorIdx]['monitData'] = [...copiedArr[selSensorIdx]['monitData'], ...convertedData];
            setWholeDatas([...copiedArr]);
            setCurPageData(prevObj => ({
                ...prevObj,
                monitData: [...copiedArr[selSensorIdx]["monitData"]]
            }))
            setLastId(convertedData[convertedData.length - 1].dataId);
            setLastData(res.data.end);

            // preventRef.current = true;
            console.log('실행 완료')
        } catch (e) {
            console.error(e);
        }
    }, [lastId, wholeDatas])
    // 수정 버튼 이벤트
    const onSubmitEdit = async e => {
        e.preventDefault();
        const selData = { ...curPageData["monitData"][selDataIdx] };
        // formData에 상태관리 중이던 값들을 변환, NaN 오류 방지
        const sortedFormData = formData.map(item => {
            const retArr = [];
            const convTime = Object.is(parseFloat(item.time), NaN) ? 0.0 : Number.isInteger(parseFloat(item.time)) ? parseFloat(item.time).toFixed(1) : parseFloat(item.time);
            const convData1 = Object.is(parseFloat(item.data1), NaN) ? 0.0 : Number.isInteger(parseFloat(item.data1)) ? parseFloat(item.data1).toFixed(1) : parseFloat(item.data1);
            retArr.push(convTime);
            retArr.push(convData1);
            if (item.data2) {
                const convData2 = Object.is(parseFloat(item.data2), NaN) ? 0.0 : Number.isInteger(parseFloat(item.data2)) ? parseFloat(item.data2).toFixed(1) : parseFloat(item.data2);
                retArr.push(convData2);
            }
            return retArr;
        });

        // 'time' 열을 기준으로 오름차순 정렬
        sortedFormData.sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

        // '일자' 데이터 YYYYMMDD_hhmmss 형태로 변환

        let csvStr = '';
        for (const item of sortedFormData) {
            const dataStr = `${item[0]},${item[1]}${item[2] ? (',' + item[2]) : ''};`
            csvStr = csvStr + dataStr;
        };
        const reqData = {
            data: csvStr,
        }



        await axios.post(`${API_URL}/monitoring-manage/updateMonit`, { data: reqData, dataId: selData.dataId, isAbout: dataStructures[selSensorIdx]["setName"], }).then(res => {
            const { success, data } = res.data;
            if (success) {
                updateOneData(data);
            } else {
                console.log(res.data.error);
                window.alert('업데이트에 실패했습니다. 잠시 후 다시 시도해 주세요.');
            }
        }).catch(err => { console.error(err) })

    };

    // 취소 버튼 이벤트
    const onReset = e => {
        const selData = { ...curPageData["monitData"][selDataIdx] };
        setFormData(selData.data.map((el, idx) => {
            const retObj = { "id": idx };
            for (let i = 0; i < el.length; i++) {
                if (i === 0) {
                    retObj["time"] = parseFloat(el[i]);
                } else {
                    retObj[`data${i}`] = parseFloat(el[i]);
                };
            };
            return (retObj);
        }));
        setChanged(false);
        setIsEditMode(false);
    };
    const updateOneData = (propData) => {
        const { updatedData, dataStructure } = propData;
        const { dataId, data } = updatedData;
        const monitData = [...curPageData['monitData']];
        const dataIdx = monitData.findIndex(obj => obj.dataId === dataId);
        const dataArr = data.split(';');
        dataArr.pop();
        const arrayConvertedDataArr = dataArr.map(el => el.split(','));

        const convertedData = {
            ...updatedData, data: arrayConvertedDataArr,
        };
        const copiedArr = [...wholeDatas];
        copiedArr[selSensorIdx]['monitData'][dataIdx] = convertedData;
        setWholeDatas([...copiedArr]);
    }

    function makeCsvData() {
        const selData = { ...curPageData["monitData"][selDataIdx] };
        const dataNamesStr = dataStructures[selSensorIdx].dataName;
        const labelArr = ["time", ...dataNamesStr];
        const dataArr = selData.data;
        const mergedArr = [labelArr, ...dataArr];
        return mergedArr;
    }

    const onClickChkSumData = e => {
        e.preventDefault();
        navigate(`/home/data/sumData/detail/${curPageData["monitData"][selDataIdx]["sumData"]["dataId"]}`);
    };

    function closeModal() {
        setChkDelModalIsOpen(false);
    }

    // selData에 변동이 있으면 input에 각 데이터를 배치
    useEffect(() => {
        // setTableLoading(true);
        const selData = curPageData && selDataIdx !== -1 ? { ...curPageData["monitData"][selDataIdx] } : null;
        const convData = selData ? selData.data.map((el, idx) => {
            const retObj = { "id": idx };
            for (let i = 0; i < el.length; i++) {
                if (i === 0) {
                    retObj["time"] = parseFloat(el[i]);
                } else {
                    retObj[`data${i}`] = parseFloat(el[i]);
                };
            };
            return (retObj);
        }) : [];
        setFormData([...convData]);
    }, [selSensorIdx, selDataIdx]);

    const [blockPopup, setBlockPopup] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) =>
        changed &&
        currentLocation.pathname !== nextLocation.pathname);

    useEffect(() => {
        if (blocker.state === "blocked") {
            setBlockPopup(true);
        } else {
            setBlockPopup(false);
        }
    }, [blocker.state]);

    useEffect(() => {
        getInitMonit();
        console.log('initMonit')
    }, []);
    return (
        isLoading ? <Loading /> :
            <React.Fragment>
                <div className='monitoringPage'>
                    <p className='path'>데이터 관리<span className='below'>{'>'}</span>세부 데이터</p>
                    <h2 className='pageTitle'>세부 데이터</h2>
                    <div className='btnsArea'>
                        <Scrollbars style={{ width: '100%' }} >
                            <div className='scrollArea' style={{ minWidth: `${200 * dataStructures.length}px` }}>
                                {
                                    dataStructures.map((item, idx, arr) => {
                                        return (
                                            <div className='sensorOnOffArea' key={idx}>
                                                <p>
                                                    <input className='sensorTypeRadio' type='radio' name='sensorType' id={item.setName} value={idx} onChange={onChangeSensorType} checked={selSensorIdx === idx} />
                                                    <label htmlFor={item.setName} className='sensorTitle'><span className={selSensorIdx === idx ? 'radioVisual on' : 'radioVisual off'} />{item.name}</label>
                                                </p>
                                                <p>
                                                    <span className='setName'>
                                                        {item.setName}
                                                    </span>
                                                    <button className={dataStructures[idx].saveState ? 'toggleBtn on' : 'toggleBtn off'} onClick={e => onChangeToggle(e, idx)}>
                                                        <div>
                                                            <span>OFF</span>
                                                            <div className='toggleIcon' />
                                                            <span>ON</span>
                                                        </div>
                                                    </button>
                                                </p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    <div className='monitArea'>
                        {/* 좌측 데이터 목록 컴포넌트 */}
                        <DataListMenu
                            monitData={selSensorIdx >= 0 ? curPageData["monitData"] : []}
                            selSensorIdx={selSensorIdx}
                            selDataIdx={selDataIdx}
                            setSelDataIdx={setSelDataIdx}
                            getPrevData={e => {
                                e.preventDefault();
                                console.log('이전 데이터 추가');
                                getPrevData(e);
                            }}
                            getMoreData={e => {
                                e.preventDefault();
                                console.log('새 데이터 추가');
                                getData(dataStructures[selSensorIdx]["setName"]);
                            }}
                            isLastData={lastData}
                        />
                        <div className='monitPrintArea'>
                            {
                                selDataIdx === -1 ? null :
                                    <div className='monitContainer'>
                                        <div className='monitTblDiv'>
                                            <div className='monitTblTop'>
                                                <div className='sumDataInfoDiv'>
                                                    <SumDataInfo data={curPageData["monitData"][selDataIdx]} />
                                                    <button className='toSumData' onClick={onClickChkSumData} disabled={!curPageData["monitData"][selDataIdx]["sumData"]["dataId"]}>
                                                        <span>공정 요약</span>
                                                        <span className='icon'>
                                                            →
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className='btnsArea'>
                                                    <div className={!isEditMode ? 'viewModeBtnsArea visible' : 'viewModeBtnsArea invisible'}>
                                                        <CSVLink className='saveCSVBtn' data={makeCsvData()} filename={`${companyInitial}${sensorTypeInitial}${curPageData["monitData"][selDataIdx]["date"]}`}>
                                                            <img src={csvSaveIcon} alt='csv 저장' />
                                                            <span>CSV 저장</span>
                                                        </CSVLink>
                                                        <button className='deleteDataBtn' onClick={e => { e.preventDefault(); setChkDelModalIsOpen(true); }}>
                                                            데이터 삭제
                                                        </button>
                                                        <button className={isEditMode ? 'editBtn editModeBtn activated' : 'editBtn editModeBtn deactivated'} onClick={e => {
                                                            e.preventDefault();
                                                            setIsEditMode(!isEditMode);
                                                        }} disabled={userIsNormal}><img src={editModeIcon} /></button>
                                                    </div>
                                                    <div className={isEditMode ? 'editModeBtnsArea visible' : 'editModeBtnsArea invisible'}>
                                                        <button className='editBtn' onClick={onSubmitEdit} disabled={!changed}>저장</button>
                                                        <button className='cancelBtn' onClick={onReset} >취소</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='monitTblArea'>
                                                {/* 우상단 데이터 테이블 컴포넌트 */}
                                                <MonitTable dataStructures={dataStructures} selSensorIdx={selSensorIdx} selDataIdx={selDataIdx} formData={formData} onChangeInput={onChangeInput} userIsNormal={userIsNormal} isEditMode={isEditMode}
                                                // loading={tableLoading} setLoading={setTableLoading}
                                                />
                                            </div>
                                        </div>
                                        <div className='chartArea'>
                                            <div className='lineChartArea'>
                                                {/* 차트 컴포넌트 */}
                                                <CustomLineChart
                                                    data={formData}
                                                    dsData={curPageData["dataStructure"]}
                                                />
                                                <ChartInfo data={{ ...curPageData["monitData"][selDataIdx], data: formData }} dataStructure={curPageData.dataStructure} />
                                                <div className='legendDiv'>
                                                    <div className='legend'>
                                                        <div className='stroke' style={{ backgroundColor: '#0A2B83' }} />
                                                        <p>{curPageData.dataStructure.dataName[0]}</p>
                                                    </div>
                                                    <div className={curPageData.dataStructure.dataName.length < 2 ? 'legend invisible' : 'legend'}>
                                                        <div className='stroke' style={{ backgroundColor: '#45BFCE' }} />
                                                        <p>{curPageData.dataStructure.dataName[1]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <YouWereEditing
                    isOpen={blockPopup}
                    onRequestClose={e => { e.preventDefault(); blocker.reset(); }}
                    onSubmit={e => { e.preventDefault(); blocker.proceed(); }}
                />
                <ChkDelModal
                    isOpen={chkDelModalIsOpen}
                    onRequestClose={closeModal}
                    onSubmit={onDeleteData}
                />
            </React.Fragment>
    );
}

function SumDataInfo({ data }) {
    return (<div className='sumDataInfoArea'>
        <p>
            <span>카운트 : </span>
            <span>{data["serialNo"] ?? '　-　'}</span>
        </p>
        <p>
            <span>차종 : </span>
            <span>{data["sumData"]["model.modelType"] ?? '　-　'}</span>
        </p>
        <p>
            <span>품명 : </span>
            <span>{data["sumData"]["model.modelName"] ?? '　-　'}</span>
        </p>
        <p>
            <span>품번 : </span>
            <span>{data["sumData"]["model.modelNo"] ?? '　-　'}</span>
        </p>
    </div>);
};

function ChartInfo({ data, dataStructure }) {
    const convertedData = data.data;
    const data1Arr = convertedData.map(el => parseFloat(el.data1));
    const data2Arr = convertedData.map(el => parseFloat(el.data2 ?? 0));
    const max1 = Math.max(...data1Arr);
    const max2 = Math.max(...data2Arr);
    const dateData = data.date;
    const year = parseInt(dateData.substr(0, 4));
    const month = parseInt(dateData.substr(4, 2));
    const date = parseInt(dateData.substr(6, 2));
    const hours = parseInt(dateData.substr(9, 2));
    const minutes = parseInt(dateData.substr(11, 2));
    const seconds = parseInt(dateData.substr(13, 2));
    const ampm = hours < 12 ? '오전' : '오후';
    console.log(data)

    if (dataStructure.name !== "하중센서") {
        return (<div className='chartInfoArea'>
            <p>
                <span>
                    {"생산 일시 : "}
                </span>
                <span>
                    {`${year}년 ${month}월 ${date}일 ${ampm} ${hours > 12 ? hours - 12 : hours}시 ${minutes}분 ${seconds}초`}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.dataName[0]} : `}
                </span>
                <span>
                    {max1.toFixed(2)}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.dataName[1]} : `}
                </span>
                <span>
                    {max2.toFixed(2)}
                </span>
            </p>
        </div>);
    } else {
        return (<div className='chartInfoArea'>
            <p>
                <span>
                    {"생산 일시 : "}
                </span>
                <span>
                    {`${year}년 ${month}월 ${date}일 ${ampm} ${hours > 12 ? hours - 12 : hours}시 ${minutes}분 ${seconds}초`}
                </span>
            </p>
            <p>
                <span>
                    {`${dataStructure.dataName[0]} : `}
                </span>
                <span>
                    {max1.toFixed(2)}
                </span>
            </p>
            <p>
                <span>
                    {`이상패턴 : `}
                </span>
                <span>
                    {data["sumData"]["abnormalPattern"] ?? '-'}
                </span>
            </p>
        </div>);
    }

}

export default RealTimeMonitoring;