import React from 'react';
import Select from 'react-select';
import CustomCheckbox from '../../../components/CustomCheckbox';
import sortingBtnIconImg from '../../../../images/sortingBtnIcon.png';
import axios from 'axios';
import { API_URL } from '../../../../config/constants';
import filledArrowIconImg from '../../../../images/filledArrowIcon.png';

function SumDataThead({
    modelTypes,
    modelNames,
    modelNos,
    chkAll, setChkAll,
    idsInThisPage,
    setChkedData,
    onSorting,
    sortingAsc,
    onFiltering,
    filter_modelType,
    filter_modelName, setFilter_modelName,
    filter_modelNo, setFilter_modelNo,
    setModelNames,
    setModelNos,
}) {
    const decisionOpt = [
        { label: 'all', value: 'all' },
        { label: 'NG', value: 'NG' },
        { label: 'OK', value: 'OK' },
    ];

    const modelTypeOpt = modelTypes.map(item => ({ label: item, value: item }));
    const modelNameOpt = modelNames.map(item => ({ label: item, value: item }));
    const modelNoOpt = modelNos.map(item => ({ label: item, value: item }));

    const commentOpt = [
        { label: 'all', value: 'all' },
        { label: '유', value: 'pres' },
        { label: '무', value: 'abs' },
    ];
    return (
        <thead>
            <tr>
                <th className='dataChkbox' rowSpan={3}>
                    <div>
                        <CustomCheckbox
                            id={'checkbox_chkAll'}
                            checked={chkAll}
                            onChange={(e) => {
                                if (!chkAll) {
                                    setChkAll(true);
                                    setChkedData(idsInThisPage);
                                } else {
                                    setChkAll(false);
                                    setChkedData([]);
                                };
                            }}
                            value={"all"}
                        />
                    </div>
                </th>
                <th className='dataTime' rowSpan={3}>
                    <div className='nameNSorting'>
                        <span>시간</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'time', !sortingAsc.time)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.time ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataDecision' rowSpan={3}>
                    <div className='withDropdown'>
                        <div className='nameNSorting'>
                            <span>판정</span>
                            <button className='sortingBtn' onClick={e => onSorting(e, 'decision', !sortingAsc.decision)}>
                                <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.decision ? 'rotate(0)' : 'rotate(180deg)' }} />
                            </button>
                        </div>
                        <Select
                            options={decisionOpt}
                            defaultValue={decisionOpt[0]}
                            isSearchable={false}
                            onChange={sel => onFiltering("decision", sel.value)}
                            classNames={{
                                control: (state) => 'control',
                                container: (state) => 'selectBox',
                                dropdownIndicator: (state) => 'arrow',
                                menu: (state) => 'options',
                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                singleValue: (state) => 'singleVal',
                                valueContainer: (state) => 'valueContainer',
                            }}
                            components={{
                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                IndicatorSeparator: (state) => null,
                            }}
                        />
                    </div>
                </th>
                <th className='dataModelType' rowSpan={3}>
                    <div className='withDropdown'>
                        <div className='nameNSorting'>
                            <span>차종</span>
                            <button className='sortingBtn' onClick={e => onSorting(e, 'modelType', !sortingAsc.modelType)}>
                                <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.modelType ? 'rotate(0)' : 'rotate(180deg)' }} />
                            </button>
                        </div>
                        <Select
                            options={modelTypeOpt}
                            isSearchable={false}
                            value={filter_modelType === "all" ? modelTypeOpt[0] : modelTypeOpt.filter(val => val.value === filter_modelType)}
                            onChange={async (sel) => {
                                if (sel === "all") {
                                    setFilter_modelNo("all");
                                    setModelNos(["all"]);
                                    setFilter_modelName("all");
                                    setModelNames(["all"]);
                                } else {
                                    await axios.post(`${API_URL}/android/getModelNames`, { modelType: sel.value }).then(res => {
                                        setFilter_modelNo("all");
                                        setModelNos(["all"]);
                                        setFilter_modelName("all");
                                        setModelNames(["all", ...res.data]);
                                    }).catch(err => console.error(err));
                                }
                                onFiltering('modelType', sel.value);
                            }}
                            classNames={{
                                control: (state) => 'control',
                                container: (state) => 'selectBox',
                                dropdownIndicator: (state) => 'arrow',
                                menu: (state) => 'options',
                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                singleValue: (state) => 'singleVal',
                                valueContainer: (state) => 'valueContainer',
                            }}
                            components={{
                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                IndicatorSeparator: (state) => null,
                            }}
                        />
                    </div>
                </th>
                <th className='dataModelName' rowSpan={3}>
                    <div className='withDropdown'>
                        <div className='nameNSorting'>
                            <span>품명</span>
                            <button className='sortingBtn' onClick={e => onSorting(e, 'modelName', !sortingAsc.modelName)}>
                                <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.modelName ? 'rotate(0)' : 'rotate(180deg)' }} />
                            </button>
                        </div>
                        <Select
                            options={modelNameOpt}
                            isSearchable={false}
                            value={filter_modelName === "all" ? modelNameOpt[0] : modelNameOpt.filter(val => val.value === filter_modelName)}

                            onChange={async (sel) => {
                                if (sel.value === "all") {
                                    setFilter_modelNo("all");
                                    setModelNos(["all"]);
                                } else {
                                    await axios.post(`${API_URL}/android/getModelNos`, { modelType: filter_modelType, modelName: sel.value }).then(res => {
                                        setFilter_modelNo("all");
                                        const strArr = res.data.map(item => item.modelNo);
                                        setModelNos(["all", ...strArr]);
                                    }).catch(err => console.error(err));
                                }
                                onFiltering('modelName', sel.value)
                            }}
                            classNames={{
                                control: (state) => 'control',
                                container: (state) => 'selectBox',
                                dropdownIndicator: (state) => 'arrow',
                                menu: (state) => 'options',
                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                singleValue: (state) => 'singleVal',
                                valueContainer: (state) => 'valueContainer',
                            }}
                            components={{
                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                IndicatorSeparator: (state) => null,
                            }}
                        />
                    </div>
                </th>
                <th className='dataModelNo' rowSpan={3}>
                    <div className='withDropdown'>
                        <div className='nameNSorting'>
                            <span>품번</span>
                            <button className='sortingBtn' onClick={e => onSorting(e, 'modelNo', !sortingAsc.modelNo)}>
                                <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.modelNo ? 'rotate(0)' : 'rotate(180deg)' }} />
                            </button>
                        </div>
                        <Select
                            options={modelNoOpt}
                            isSearchable={false}
                            value={filter_modelNo === "all" ? modelNoOpt[0] : modelNoOpt.filter(val => val.value === filter_modelNo)}
                            onChange={sel => onFiltering('modelNo', sel.value)}
                            classNames={{
                                control: (state) => 'control',
                                container: (state) => 'selectBox',
                                dropdownIndicator: (state) => 'arrow',
                                menu: (state) => 'options',
                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                singleValue: (state) => 'singleVal',
                                valueContainer: (state) => 'valueContainer',
                            }}
                            components={{
                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                IndicatorSeparator: (state) => null,
                            }}
                        />
                    </div>
                </th>
                <th className='dataCount' rowSpan={3}>
                    <div className='nameNSorting'>
                        <span>카운트</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'count', !sortingAsc.count)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.count ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterial title' colSpan={7}><div>원자재</div></th>
                <th className='dataLoad title' colSpan={4}><div>하중</div></th>
                <th className='dataSize title' colSpan={5}><div>치수</div></th>

                <th className='dataNgFactor' rowSpan={3}>
                    <div>
                        NG 요인
                    </div>
                </th>
                <th className='dataComment' rowSpan={3}>
                    <div className='withDropdown'>
                        <span>코멘트</span>
                        <Select
                            options={commentOpt}
                            defaultValue={commentOpt[0]}
                            isSearchable={false}
                            onChange={sel => onFiltering("comment", sel.value)}
                            classNames={{
                                control: (state) => 'control',
                                container: (state) => 'selectBox',
                                dropdownIndicator: (state) => 'arrow',
                                menu: (state) => 'options',
                                option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                singleValue: (state) => 'singleVal',
                                valueContainer: (state) => 'valueContainer',
                            }}
                            components={{
                                DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                IndicatorSeparator: (state) => null,
                            }}
                        />
                    </div>
                </th>
                <th className='dataDetail' rowSpan={3}><div>상세보기</div></th>
            </tr>
            <tr>
                <th className='dataMaterialYS title secondTh' colSpan={2}><div>YS</div></th>
                <th className='dataMaterialUTS title secondTh' colSpan={2}><div>UTS</div></th>
                <th className='dataMaterialEU title secondTh' colSpan={2}><div>EU</div></th>
                <th className='dataMaterialDetail title secondTh detail' rowSpan={2}><div>상세</div></th>
                <th className='dataPeak title secondTh' colSpan={2}><div>피크</div></th>
                <th className='dataAbnormalPattern title secondTh'><div>이상패턴</div></th>
                <th className='dataLoadDetail title secondTh detail' rowSpan={2}><div>상세</div></th>
                <th className='dataSizeLeft title secondTh' colSpan={2}><div>좌</div></th>
                <th className='dataSizeRight title secondTh' colSpan={2}><div>우</div></th>
                <th className='dataSizeDetail title secondTh detail' rowSpan={2}><div>상세</div></th>
            </tr>
            <tr>
                <th className='dataMaterialYS thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialYS', !sortingAsc.materialYS)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialYS ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterialYSGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialYSGap', !sortingAsc.materialYSGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialYSGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterialUTS thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialUTS', !sortingAsc.materialUTS)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialUTS ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterialUTSGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialUTSGap', !sortingAsc.materialUTSGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialUTSGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterialEU thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialEU', !sortingAsc.materialEU)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialEU ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataMaterialEUGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'materialEUGap', !sortingAsc.materialEUGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.materialEUGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataPeak thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'peak', !sortingAsc.peak)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.peak ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataPeakGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'peakGap', !sortingAsc.peakGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.peakGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataAbnormalPattern thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'abnormalPattern', !sortingAsc.abnormalPattern)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.abnormalPattern ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataSizeLeft thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'sizeLeft', !sortingAsc.sizeLeft)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.sizeLeft ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataSizeLeftGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'sizeLeftGap', !sortingAsc.sizeLeftGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.sizeLeftGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataSizeRight thirdTh val'>
                    <div className='nameNSorting'>
                        <span>값</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'sizeRight', !sortingAsc.sizeRight)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.sizeRight ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
                <th className='dataSizeRightGap thirdTh gap'>
                    <div className='nameNSorting'>
                        <span>편차</span>
                        <button className='sortingBtn' onClick={e => onSorting(e, 'sizeRightGap', !sortingAsc.sizeRightGap)}>
                            <img src={sortingBtnIconImg} alt={'정렬'} style={{ transform: sortingAsc.sizeRightGap ? 'rotate(0)' : 'rotate(180deg)' }} />
                        </button>
                    </div>
                </th>
            </tr>
            <tr style={{ height: '6px' }} />
        </thead>
    );
}

export default SumDataThead;