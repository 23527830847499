import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useOutletContext } from 'react-router';
import { Link } from 'react-router-dom';
import sumDataIcon_on from '../../images/sumDsIcon_on.png';
import sumDataIcon_off from '../../images/sumDsIcon_off.png';
import monitoringIcon_on from '../../images/rawDsIcon_on.png';
import monitoringIcon_off from '../../images/rawDsIcon_off.png';
import './dataStructure.scss';

function DataStructure(props) {
    const { normalRank } = useOutletContext();
    const { pathname } = useLocation();
    const { userRank } = useSelector((state) => state.session);
    return (
        <div className='dsPage'>
            <div className='dsMenuArea'>
                <ul className='dsMenuUl'>
                    <li className='dsMenuLi'>
                        <Link className={pathname.split('/')[3] === 'sumData' ? 'dsMenuBtn on' : 'dsMenuBtn'} to={'/home/dataStructure/sumData'}>
                            <img className='dsMenuIcon' src={pathname.split('/')[3] === 'sumData' ? sumDataIcon_on : sumDataIcon_off} alt={"공정 요약"} />
                            <span>요약 데이터 구조</span>
                        </Link>
                    </li>
                    <li className='dsMenuLi'>
                        <Link className={pathname.split('/')[3] === 'monitoring' ? 'dsMenuBtn on' : 'dsMenuBtn'} to={'/home/dataStructure/monitoring'}>
                            <img className='dsMenuIcon' src={pathname.split('/')[3] === 'monitoring' ? monitoringIcon_on : monitoringIcon_off} alt={"세부 데이터"} />
                            <span>세부 데이터 구조</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className='dsContentArea'>
                <Outlet context={{ userIsNormal: userRank === normalRank }} />
            </div>
        </div>
    );
}

export default DataStructure;