import React from 'react';

function IndexLvTds({ outArr, title, inputType = 'text', onChange }) {
    const defalutValue = inputType === 'number' ? 0 : ''
    return (
        <React.Fragment>
            {
                outArr.map((innArr, index) => (innArr.map((_, idx) => (<td  className='indexTd' key={`${title}_${index}_${idx}`}>
                    <input type={inputType} value={outArr[index][idx] ?? defalutValue} onChange={e => onChange(e, title, index, idx)} />
                </td>))))
            }
        </React.Fragment>
    );
}

export default IndexLvTds;