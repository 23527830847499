import React from 'react';
import { Outlet } from 'react-router';

function SumDataStructs(props) {
    return (
        <div className='dataStructure'>
            <Outlet context={{}} />
        </div>
    );
}

export default SumDataStructs;