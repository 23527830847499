import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import Loading from '../../components/Loading';
import useAsync from '../../../hooks/useAsync';
import './SumDataStruct.scss';
import { useNavigate } from 'react-router';
import { ConfirmContext } from '../../components/ConfirmModal';
import createIconImg from '../../../images/createIcon.png';

function ShowSumDataStructure(props) {

    const navigate = useNavigate();
    const { confirm } = useContext(ConfirmContext);
    async function getSumDataStructs() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getSumDataStructs`);
        return response.data;
    }

    const onClickEditBtn = (e, idNo) => {
        e.preventDefault();
        navigate(`/home/dataStructure/sumData/edit/${idNo}`);
    }

    const onClickDeleteBtn = async (e, selectedData) => {
        e.preventDefault();
        const result = await confirm(`${selectedData.name}를(을) 삭제하시겠습니까?`, '삭제', '취소');
        if (result) {
            await axios.post(`${API_URL}/monitoring-manage/deleteSumDataStruct`, { sumDsId: selectedData.sumDsId }).then(res => {
                if (res.data.success) {
                    navigate(0);
                }
            })
        }
    }

    const state = useAsync(getSumDataStructs);
    const { loading, error, data: result } = state;
    if (loading) return <div className='contentArea' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='contentArea'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return <div>실패</div>;

    console.log(result)
    return (
        <div className='showSumDataStructs'>
            <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>요약 데이터 구조</p>
            <h2 className='pageTitle'>요약 데이터 구조</h2>

            <ul className='sumDsTbl dataTable'>
                <li className='thRow'>
                    <ul className='tr'>
                        <li className='name'>데이터 이름</li>
                        <li className='dataNo'>센서 데이터 수</li>
                        <li className='dataName'>센서 데이터 이름</li>
                        <li className='indexNo'>공정지표 수</li>
                        <li className='indexName'>공정지표 이름</li>
                        <li className='indexType'>데이터 유형</li>
                        <li className='indexDim'>단위</li>
                        <li className='indexStd'>기준값</li>
                        <li className='indexTol1'>오차 상한</li>
                        <li className='indexTol0'>오차 하한</li>
                        <li className='indexComment'>공정지표 코멘트</li>
                        <li className='organization'>소속</li>
                        <li className='userOwner'>담당자</li>
                        <li className='mqttTopic'>MQTT 토픽</li>
                        <li className='jsonKeys'>JSON Key</li>
                        <li className='comment'>코멘트</li>
                        <li className='edit'>수정</li>
                        <li className='delete'>삭제</li>
                    </ul>
                </li>
                {
                    result.map((item, index) => {
                        const wholeRowAmount = item?.indexNo.reduce((prev, cur) => prev + cur, 0);
                        return (
                            <li className='tdRow' key={`sumDsId${item.sumDsId}`}>
                                <ul className='tr'>
                                    <li className='name td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.name}</li>
                                    <li className='dataNo td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.dataNo}</li>
                                    <li className='dataName'>
                                        <ul>
                                            {
                                                item.dataName.map((name, arrIdx) => {
                                                    const idxAmt = item.indexNo[arrIdx];
                                                    return (
                                                        <li className='dataNameInner td' key={`dataNameInner_${arrIdx}`} style={{ height: `${idxAmt * 30}px` }}>
                                                            {name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                    <li className='indexNo'>
                                        <ul>
                                            {
                                                item.indexNo.map((indexNo, arrIdx) => (<li className='indexNoInner td' key={`indexNoInner_${arrIdx}`} style={{ height: `${indexNo * 30}px` }}>
                                                    {indexNo}
                                                </li>))
                                            }
                                        </ul>
                                    </li>
                                    <li className='indexName'>{
                                        item.indexName.map((secondArr, arrIdx) => (<ul key={`indexNameUl_${arrIdx}`}>
                                            {
                                                secondArr.map((indexName, innerIdx) => (<li className='indexNameInner td' key={`indexNameInner_${innerIdx}`}>{indexName}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexType'>{
                                        item.indexType.map((secondArr, arrIdx) => (<ul key={`indexTypeUl_${arrIdx}`}>
                                            {
                                                secondArr.map((indexType, innerIdx) => (<li className='indexTypeInner td' key={`indexTypeInner_${innerIdx}`}>{indexType}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexDim'>{
                                        item.indexDim.map((secondArr, arrIdx) => (<ul key={`indexDimUl_${arrIdx}`}>
                                            {
                                                secondArr.map((indexDim, innerIdx) => (<li className='indexDimInner td' key={`indexDimInner_${innerIdx}`}>{indexDim ?? '-'}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexStd'>{
                                        item.indexStd.map((secondArr, arrIdx) => (<ul key={`indexStdUl_${arrIdx}`}>
                                            {
                                                secondArr.map((indexStd, innerIdx) => (<li className='indexStdInner td' key={`indexStdInner_${innerIdx}`}>{indexStd ?? '-'}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexTol1'>{
                                        item.indexTol1.map((secondArr, arrIdx) => (<ul key={`indexTol1Ul_${arrIdx}`}>
                                            {
                                                secondArr.map((indexTol1, innerIdx) => (<li className='indexTol1Inner td' key={`indexTol1Inner_${innerIdx}`}>{indexTol1 ?? '-'}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexTol0'>{
                                        item.indexTol0.map((secondArr, arrIdx) => (<ul key={`indexTol0Ul_${arrIdx}`}>
                                            {
                                                secondArr.map((indexTol0, innerIdx) => (<li className='indexTol0Inner td' key={`indexTol0Inner_${innerIdx}`}>{indexTol0 ?? '-'}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='indexComment'>{
                                        item.indexComment.map((secondArr, arrIdx) => (<ul key={`indexCommentUl_${arrIdx}`}>
                                            {
                                                secondArr.map((indexComment, innerIdx) => (<li className='indexCommentInner td' key={`indexCommentInner_${innerIdx}`}>{indexComment ?? '-'}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='organization td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.organization}</li>
                                    <li className='userOwner td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.userOwner.join(', ')}</li>
                                    <li className='mqttTopic td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.mqttTopic}</li>
                                    <li className='jsonKeys'>{
                                        item.jsonKeys.map((secondArr, arrIdx) => (<ul key={`jsonKeysUl_${arrIdx}`}>
                                            {
                                                secondArr.map((jsonKeys, innerIdx) => (<li className='jsonKeysInner td' key={`jsonKeysInner_${innerIdx}`}>{jsonKeys}</li>)
                                                )
                                            }
                                        </ul>))
                                    }</li>
                                    <li className='comment td' style={{ height: `${wholeRowAmount * 30}px` }}>{item.comment ?? '-'}</li>
                                    <li className='edit td' style={{ height: `${wholeRowAmount * 30}px` }}>
                                        <button className='editBtn' onClick={e => onClickEditBtn(e, item.sumDsId)}>
                                            수정
                                        </button>
                                    </li>
                                    <li className='delete td' style={{ height: `${wholeRowAmount * 30}px` }}>
                                        <button className='deleteBtn' onClick={e => onClickDeleteBtn(e, item)}>
                                            삭제
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )
                    })
                }
            </ul>
                <div className='btnsArea'>
                <button className='createBtn' onClick={e => {
                    e.preventDefault();
                    navigate('/home/dataStructure/sumData/create')
                }} >추가<img src={createIconImg} alt={"추가"} /></button>
                </div>
        </div>
    );
}

export default ShowSumDataStructure;