import React from 'react';

function DataLvTds({ arr, indexNo, title, inputType = 'text', onChange }) {
    const defalutValue = inputType === 'number' ? 0 : ''
    return (
        <React.Fragment>
            {
                arr.map((_, index) => {
                    return (<td colSpan={indexNo[index]} key={`${title}_${index}`}>
                        <input type={inputType} value={arr[index] ?? defalutValue} onChange={e => onChange(e, title, index)} min={1} />
                    </td>)
                })
            }
        </React.Fragment>
    );
}

export default DataLvTds;