import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import Pagination from '../../components/Pagination';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import "react-datepicker/dist/react-datepicker.css";
import filledArrowIconImg from '../../../images/filledArrowIcon.png';
import resetIconImg from '../../../images/resetIcon.png';
import ChkDelModal from '../../components/ChkDelModal';
import { CustomDatePickerStart, CustomDatePickerEnd } from '../../components/CustomDatePicker';
import moment from 'moment';
import Select from 'react-select';
import SumDataThead from './components/SumDataThead';
import SumDataTbodyRow from './components/SumDataTbodyRow';
import csvSaveIcon from '../../../images/csvSave.png';
import { CSVLink } from 'react-csv';
import editModeIcon from '../../../images/editDS.png';
import refreshIcon from '../../../images/refresh.png';

function ShowData(props) {
    const { modelTypeArr, modelNameArr, modelNoArr, modelData, chkAll, setChkAll, chkedData, setChkedData, dataArr, setDataArr, startedAt, rowCount, userIsNormal } = useOutletContext();
    const companyInitial = 'K';

    const navigate = useNavigate();

    const limit = 23;
    const [page, setPage] = useState(1);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [filter_time, setFilter_time] = useState({
        fixedStartDate: startedAt ? moment(startedAt).toDate() : moment().toDate(),
        fixedEndDate: new Date(),
    });
    const [filter_decision, setFilter_decision] = useState("all");
    const [filter_modelType, setFilter_modelType] = useState("all");
    const [filter_modelName, setFilter_modelName] = useState("all");
    const [filter_modelNo, setFilter_modelNo] = useState("all");
    const [filter_comment, setFilter_comment] = useState("all");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectPeriod, setSelectPeriod] = useState("currentWorks");
    const [sortingAsc, setSortingAsc] = useState({
        time: false,
        count: false,
        decision: false,
        modelType: false,
        modelName: false,
        modelNo: false,
        materialYS: false,
        materialYSGap: false,
        materialUTS: false,
        materialUTSGap: false,
        materialEU: false,
        materialEUGap: false,
        peak: false,
        peakGap: false,
        abnormalPattern: false,
        sizeLeft: false,
        sizeLeftGap: false,
        sizeRight: false,
        sizeRightGap: false,
    });
    const [sortingArr, setSortingArr] = useState([]);
    const [modelTypes, setModelTypes] = useState(["all"]);
    const [modelNames, setModelNames] = useState(["all"]);
    const [modelNos, setModelNos] = useState(["all"]);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const [curPageData, setCurPageData] = useState([]);
    const [changed, setChanged] = useState(false);
    const [editedData, setEditedData] = useState([]);
    const [resetting, setResetting] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    function onFiltering(condition, value) {
        switch (condition) {
            case 'decision':
                setFilter_decision(value);
                break;
            case 'modelType':
                setFilter_modelType(value);
                break;
            case 'modelName':
                setFilter_modelName(value);
                break;
            case 'modelNo':
                setFilter_modelNo(value);
                break;
            case 'comment':
                setFilter_comment(value);
            default:
                break;
        }
    };

    function onSorting(e, condition, isAsc) {
        e.preventDefault();
        // var newArray = [...dataArr];
        setSortingAsc({ ...sortingAsc, [condition]: isAsc });
        const curArr = [...sortingArr];
        const filteredArr = curArr.filter(el => el[0] !== condition);
        setSortingArr([[condition, isAsc ? "ASC" : "DESC"], ...filteredArr]);
    }

    const onRefresh = (e) => {
        e.preventDefault();
        if (selectPeriod === 'currentWorks') {
            setEndDate(moment().toDate());
            setFilter_time({ ...filter_time, fixedEndDate: new Date(), });
        } else {
            getFilteredData();
        }
    }

    async function getFilteredData() {
        await axios.post(`${API_URL}/monitoring-manage/lookupSumDataByCond`, {
            filter_time, filter_decision, filter_modelType, filter_modelName, filter_modelNo, filter_comment, sortingArr, pageNo: page
        }).then(res => {
            const { sumDatas, rowCount, models } = res.data;
            setDataArr([...sumDatas]);
            setTotalRowCount(rowCount);
        })
    }

    useEffect(() => {
        getFilteredData();
    }, [filter_time, filter_decision, filter_modelType, filter_modelName, filter_modelNo, filter_comment, sortingArr, page]);

    useEffect(() => {
        // setDataArr(result.filter(item => moment(item.time).isSameOrAfter(filter_time.fixedStartDate) && moment(item.time).isSameOrBefore(filter_time.fixedEndDate)));
        setModelTypes(["all", ...modelTypeArr]);
        setModelNames(["all", ...modelNameArr]);
        setModelNos(["all", ...modelNoArr.map(item => item.modelNo)])
        setFilter_modelType(modelData.modelType ?? "all");
        setFilter_modelName(modelData.modelName ?? "all");
        setFilter_modelNo(modelData.modelNo ?? "all");
        // setRowCount(rowCount);
    }, [])

    useEffect(() => {
        setChkedData([]);
    }, [page, setChkedData])

    useEffect(() => {
        switch (selectPeriod) {
            case 'currentWorks':
                setStartDate(startedAt ? moment(startedAt).toDate() : moment().toDate());
                setEndDate(moment().toDate());
                break;
            case '1h':
                setStartDate(moment().subtract(1, 'hours').toDate());
                setEndDate(moment().toDate());
                break;
            case '24h':
                setStartDate(moment().subtract(1, 'days').toDate());
                setEndDate(moment().toDate());
                break;
            default:
                setStartDate(moment().toDate());
                setEndDate(moment().toDate());
                break;
        }
    }, [selectPeriod])
    const periodOpt = [
        { label: '시간직접입력', value: 'input' },
        { label: '현재공정', value: 'currentWorks' },
        { label: '최근 1시간', value: '1h' },
        { label: '최근 24시간', value: '24h' },
    ];

    const onChangeModel = async (index, modelObj, modelId) => {
        const copiedArr = [...curPageData];
        copiedArr[index].model = modelObj;
        copiedArr[index].modelId = modelId;
        setCurPageData([...copiedArr]);
        if (!changed) setChanged(true);
        if (!editedData.includes(copiedArr[index].dataId)) setEditedData([...editedData, copiedArr[index].dataId]);
    }

    const onChangeValue = (index, name, value) => {
        const copiedArr = [...curPageData];
        switch (name) {
            case 'count':
                copiedArr[index][name] = parseInt(value);
                break;
            case 'materialYSGap':
            case 'materialUTSGap':
            case 'materialEUGap':
            case 'peakGap':
            case 'sizeLeftGap':
            case 'sizeRightGap':
                copiedArr[index][name] = parseFloat(value) / 100;
                break;
            case 'materialYS':
            case 'materialUTS':
            case 'materialEU':
            case 'peak':
            case 'sizeLeft':
            case 'sizeRight':
                copiedArr[index][name] = parseFloat(value);
                break;
            default:
                copiedArr[index][name] = value;
                break;
        }
        setCurPageData([...copiedArr]);
        if (!changed) setChanged(true);
        if (!editedData.includes(copiedArr[index].dataId)) setEditedData([...editedData, copiedArr[index].dataId]);
    }

    const onChangeDateValue = (index, value) => {
        const copiedArr = [...curPageData];
        copiedArr[index].time = value.toISOString();
        setCurPageData([...copiedArr]);
        if (!changed) setChanged(true);
        if (!editedData.includes(copiedArr[index].dataId)) setEditedData([...editedData, copiedArr[index].dataId]);
    };

    function makeCsvData() {
        // function onSaveAsCSV(e) {
        // e.preventDefault();
        const labelArr = ["시간", "카운트", "차종", "품명", "품번", "판정", "원자재 - YS", "원자재 - YS 편차", "원자재 - YS 판정", "원자재 - UTS", "원자재 - UTS 편차", "원자재 - UTS 판정", "원자재 - EU", "원자재 - EU 편차", "원자재 - EU 판정", "하중 - 피크", "하중 - 피크 편차", "하중 - 피크 판정", "하중 - 이상패턴", "하중 - 이상패턴 판정", "치수 - 좌", "치수 - 좌(2)", "치수 - 좌 편차", "치수 - 좌 판정", "치수 - 우", "치수 - 우(2)", "치수 - 우 편차", "치수 - 우 판정", "NG 요인", "코멘트"];
        const valueArr = curPageData.map(item => {
            const arr = Object.values(item);
            arr.splice(3, 1, item.model.modelType, item.model.modelName, item.model.modelNo);
            arr.splice(arr.length - 1, 1);
            arr.splice(0, 1);
            return arr
        });
        const mergedArr = [labelArr, ...valueArr];
        return mergedArr;
    }

    // 수정 버튼 이벤트
    const handleCreateObject = async e => {
        e.preventDefault();
        // 수정 사항 id(PK)체크 후 해당 데이터만 전송
        const updatedData = curPageData.filter(el => editedData.includes(el.dataId));
        console.log(updatedData)
        await axios.post(`${API_URL}/monitoring-manage/updateSumDatas`, { updatedData: updatedData }).then(res => {
            if (res.data.success) {
                // window.location = '/model/edit';
                navigate(0);
                // onReturnDefault(e);
            } else {
                console.error(res.data.error);
            }
        }).catch(err => console.error(err));

    };
    const onReset = async (e) => {
        e.preventDefault();
        setCurPageData(dataArr);
        setResetting(true);
        // DB에서 해당 페이지의 데이터 받아오기
        await axios.post(`${API_URL}/monitoring-manage/resetEditingData`, { dataIds: editedData }).then(res => {
            const resDataArr = JSON.parse(res.data.data);
            const copiedArr = [...dataArr];
            for (let resData of resDataArr) {
                const dataIdx = copiedArr.findIndex(i => i.dataId === resData.dataId);
                copiedArr[dataIdx] = resData;
            }
            setDataArr([...copiedArr]);
            setChanged(false);
            setEditedData([]);
            setIsEditMode(false);
        });
    };

    useEffect(() => {
        setTotalRowCount(rowCount);
    }, []);

    useEffect(() => {
        console.log('커페이지데이터 교체')
        setCurPageData([...dataArr]);
        setResetting(false);
        // setOriginalData(tempArr)
    }, [dataArr]);

    useEffect(() => {
        setChanged(false);
    }, [page]);

    return (
        <React.Fragment>
            <div className='dataMainArea'>
                <p className='path'>데이터 관리<span className='below'>{'>'}</span>공정 요약</p>
                <h2 className='pageTitle'>공정 요약</h2>
                <button className='refreshBtn' onClick={onRefresh}>
                    <img src={refreshIcon} alt='새로고침' />
                </button>
                <div className='pageContent'>

                    <div className='btnsArea'>
                        <div className='periodBtnsArea'>
                            <form onSubmit={e => {
                                e.preventDefault();
                                setFilter_time({
                                    fixedStartDate: startDate,
                                    fixedEndDate: endDate,
                                });
                            }}>
                                <span className='datePickerLabel'>시간</span>
                                <div className='datePickerArea'>
                                    <div className='startDateArea'>
                                        <CustomDatePickerStart startDate={startDate} setStartDate={setStartDate} endDate={endDate} changeDropdown={() => setSelectPeriod('input')} />
                                    </div>
                                    <span>-</span>
                                    <div className='endDateArea'>
                                        <CustomDatePickerEnd startDate={startDate} endDate={endDate} setEndDate={setEndDate} changeDropdown={() => setSelectPeriod('input')} />
                                    </div>
                                </div>
                                <Select
                                    options={periodOpt}
                                    value={periodOpt.find(el => el.value === selectPeriod)}
                                    onChange={sel => setSelectPeriod(sel.value)}
                                    isSearchable={false}
                                    classNames={{
                                        control: (state) => 'control',
                                        container: (state) => 'selectBox',
                                        dropdownIndicator: (state) => 'arrow',
                                        menu: (state) => 'options',
                                        menuList: (state) => 'optionsList',
                                        option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                        singleValue: (state) => 'singleVal',
                                        valueContainer: (state) => 'valueContainer',
                                    }}
                                    components={{
                                        DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={filledArrowIconImg} alt={'드롭다운'} /></span>,
                                        IndicatorSeparator: (state) => null,
                                    }}
                                />
                                <input className='submitPeriod' type='submit' value={'적용'} />
                                <button className='resetPeriod' onClick={e => {
                                    e.preventDefault();
                                    setSelectPeriod('currentWorks');
                                    setFilter_time({
                                        fixedStartDate: startedAt ? moment(startedAt).toDate() : moment().toDate(),
                                        fixedEndDate: endDate,
                                    });
                                    setStartDate(startedAt ? moment(startedAt).toDate() : moment().toDate());
                                    setEndDate(moment().toDate());
                                }}><span>초기화</span><img src={resetIconImg} alt={"초기화"} /></button>
                            </form>
                        </div>
                        <div className='btnsArea'>
                            <div className={!isEditMode ? 'viewModeBtnsArea visible' : 'viewModeBtnsArea invisible'}>
                                <CSVLink className='saveCSVBtn' data={makeCsvData()} filename={`${companyInitial}S`}>
                                    <img src={csvSaveIcon} alt='csv 저장' />
                                    <span>CSV 저장</span>
                                </CSVLink>
                                <button className='createData' onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/home/data/sumData/create');
                                }}
                                    disabled={userIsNormal}
                                >추가</button>
                                <button className='deleteData' onClick={e => {
                                    e.preventDefault();
                                    setDelModalOpen(true);
                                }} disabled={chkedData.length === 0 || userIsNormal}>삭제</button>
                                <button className={isEditMode ? 'editBtn editModeBtn activated' : 'editBtn editModeBtn deactivated'} onClick={e => {
                                    e.preventDefault();
                                    setIsEditMode(!isEditMode);
                                }} disabled={userIsNormal}><img src={editModeIcon} /></button>
                            </div>
                            <div className={isEditMode ? 'editModeBtnsArea visible' : 'editModeBtnsArea invisible'}>
                                <div className='cscBtns'>
                                    <button className='editBtn' onClick={handleCreateObject} disabled={!changed}>저장</button>
                                    <button className='cancelBtn' onClick={onReset}>취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tblArea'>
                        <div className='tblScroll'>
                            <table>
                                <SumDataThead
                                    modelTypes={modelTypes}
                                    modelNames={modelNames}
                                    modelNos={modelNos}
                                    modelData={modelData}
                                    chkAll={chkAll}
                                    setChkAll={setChkAll}
                                    idsInThisPage={dataArr.map(item => item.dataId.toString())}
                                    setChkedData={setChkedData}
                                    onSorting={onSorting}
                                    sortingAsc={sortingAsc}
                                    onFiltering={onFiltering}
                                    filter_modelType={filter_modelType}
                                    filter_modelName={filter_modelName}
                                    setFilter_modelName={setFilter_modelName}
                                    filter_modelNo={filter_modelNo}
                                    setFilter_modelNo={setFilter_modelNo}
                                    setModelNames={setModelNames}
                                    setModelNos={setModelNos}
                                />
                                <tbody>
                                    {
                                        curPageData.map((item, index, arr) => {
                                            return (
                                                <SumDataTbodyRow
                                                    key={index}
                                                    index={index}
                                                    item={item}
                                                    chkedData={chkedData}
                                                    setChkedData={setChkedData}
                                                    onChangeDateValue={onChangeDateValue}
                                                    onChangeValue={onChangeValue}
                                                    onChangeModel={onChangeModel}
                                                    modelTypeArr={modelTypeArr}
                                                    resetting={resetting}
                                                    setResetting={setResetting}
                                                    userIsNormal={userIsNormal}
                                                    isEditMode={isEditMode}
                                                />
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination
                        total={totalRowCount}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
            <ChkDelModal
                isOpen={delModalOpen}
                onRequestClose={e => {
                    e.preventDefault();
                    setDelModalOpen(false);
                }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    const chkedDataArr = [...chkedData];
                    await axios.post(`${API_URL}/monitoring-manage/deleteSumData`, { id: chkedDataArr }).then(() => {
                        window.location.href = '/home/data/sumData';
                    }).catch(err => console.error(err));
                }}
            />
        </React.Fragment>
    );
}

export default ShowData;