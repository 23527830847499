import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import createIcon from '../../../images/createIcon.png';
import { useNavigate, useParams } from 'react-router';
import RawLvTds from './components/RawLvTds';
import Select from 'react-select';
import arrowIconImg from '../../../images/filledArrowIcon.png';

function CreateMonitDataStructure(props) {

    async function getSumDataStructs() {
        await axios.post(`${API_URL}/monitoring-manage/getSumDataStructs`).then(res => {
            const data = res.data;
            setSumDataStructs([...data]);
        })
    }

    const defaultDataForm = {
        sumDsId: 0,
        name: '',
        rawNo: 1,
        rawName: [''],
        rawType: [''],
        rawDim: [''],
        mqttTopic: '',
        comment: null,
    };

    const [formData, setFormData] = useState(defaultDataForm);
    const [changed, setChanged] = useState(false);
    const [sumDataStructs, setSumDataStructs] = useState([]);
    const navigate = useNavigate();
    const nameRef = useRef();

    console.log(formData)

    const onChange = e => {
        e.preventDefault();
        const { name, value, type } = e.target;
        let convVal = type === 'number' ? parseInt(value) : value;

        if (name === 'rawNo') {
            const curDataAmount = formData.rawNo;
            const { rawName, rawType, rawDim } = formData;
            const increaseFx = (currArr, incr, defaultValue) => ([...currArr, ...Array(incr).keys().map((_) => (defaultValue))]);
            const decreaseFx = (curArr, newAmount) => (curArr.slice(0, newAmount));
            if (convVal > curDataAmount) {
                const increase = convVal - curDataAmount;
                const newRawName = increaseFx(rawName, increase, '');
                const newRawType = increaseFx(rawType, increase, '');
                const newRawDim = increaseFx(rawDim, increase, '');
                setFormData(prev => ({
                    ...prev,
                    rawNo: convVal,
                    rawName: newRawName,
                    rawType: newRawType,
                    rawDim: newRawDim,
                }))
            } else {
                const newRawName = decreaseFx(rawName, convVal);
                const newRawType = decreaseFx(rawType, convVal);
                const newRawDim = decreaseFx(rawDim, convVal);
                setFormData(prev => ({
                    ...prev,
                    rawNo: convVal,
                    rawName: newRawName,
                    rawType: newRawType,
                    rawDim: newRawDim,
                }))
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: convVal }));
        }
    };

    const onChangeRawLevel = (e, tgtName, index) => {
        e.preventDefault();
        const { value, type } = e.target;
        let convVal = type === 'number' ? parseInt(value) : value;
        const newArray = [...formData[tgtName]];
        newArray[index] = convVal;
        setFormData(prev => ({ ...prev, [tgtName]: newArray }));
    };

    const chgMonit = () => {
        const changed = JSON.stringify(formData) !== JSON.stringify(defaultDataForm);
        setChanged(changed);
    };

    const onChangeSumDS = (sel) => {
        console.log(sel)
    };

    const onCancel = e => {
        navigate('/home/dataStructure/monitoring')
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // API 요청
        await axios.post(`${API_URL}/monitoring-manage/createDataStructure`, { newData: formData }).then(res => {
            if (!res.data.success) {
                nameRef.current.focus();
                if (res.data.result === 'dupSetName') {
                    // spanRef.current.textContent = "DB 내 중복된 setName이 존재합니다."
                } else {
                    // spanRef.current.textContent = "DB 내 중복된 테이블이 존재합니다."
                }
            } else {
                window.location.href = '/home/dataStructure/monitoring';
            }
        }).catch(e => { console.error(e) });
    };

    const optionArr = sumDataStructs.map(item => ({ label: item.name, value: item.sumDsId }));

    useEffect(() => {
        getSumDataStructs();
    }, [])
    
    useEffect(() => {
        chgMonit();
    }, [formData])
    return (
        <div className='createDetailedDataStruct'>
            <p className='path'>데이터 구조 관리<span className='below'>{'>'}</span>세부 데이터 구조<span className='below'>{'>'}</span>세부 데이터 구조 추가</p>
            <h2 className='pageTitle'>세부 데이터 구조 추가</h2>
            <table>
                <tbody>
                    <tr>
                        <th>요약 데이터 구조</th>
                        <td colSpan={formData.rawNo}>
                            <Select
                                options={optionArr}
                                onChange={sel => onChangeSumDS(sel)}
                                value={optionArr.find(el => el.value === formData.sumDsId)}
                                isSearchable={false}
                                maxMenuHeight={'225px'}
                                classNames={{
                                    control: (state) => 'control',
                                    container: (state) => 'selectBox',
                                    dropdownIndicator: (state) => 'arrow',
                                    menu: (state) => 'options',
                                    option: (state) => state.isSelected ? 'option selected' : state.isFocused ? 'option focused' : 'option',
                                    singleValue: (state) => 'singleVal',
                                    valueContainer: (state) => 'valueContainer',
                                }}
                                components={{
                                    DropdownIndicator: (state) => <span className={state.isFocused ? 'arrowIcon focused' : 'arrowIcon'} ><img src={arrowIconImg} alt={'드롭다운'} /></span>,
                                    IndicatorSeparator: (state) => null,
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>데이터 이름</th>
                        <td colSpan={formData.rawNo}>
                            <input name='name' value={formData.name ?? ''} onChange={onChange} ref={nameRef} />
                        </td>
                    </tr>
                    <tr>
                        <th>센서 데이터 수</th>
                        <td colSpan={formData.rawNo}>
                            <input type='number' name='rawNo' value={formData.rawNo ?? 1} onChange={onChange} min={1} />
                        </td>
                    </tr>
                    <tr>
                        <th>센서 데이터 이름</th>
                        <RawLvTds
                            arr={formData.rawName}
                            indexNo={formData.indexNo}
                            title={'rawName'}
                            onChange={onChangeRawLevel}
                        />
                    </tr>
                    <tr>
                        <th>데이터 유형</th>
                        <RawLvTds
                            arr={formData.rawType}
                            indexNo={formData.indexNo}
                            title={'rawType'}
                            onChange={onChangeRawLevel}
                        />
                    </tr>
                    <tr>
                        <th>단위</th>
                        <RawLvTds
                            arr={formData.rawDim}
                            indexNo={formData.indexNo}
                            title={'rawDim'}
                            onChange={onChangeRawLevel}
                        />
                    </tr>
                    <tr>
                        <th>MQTT 토픽</th>
                        <td colSpan={formData.rawNo}>
                            <input name='mqttTopic' value={formData.mqttTopic ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                    <tr>
                        <th>코멘트</th>
                        <td colSpan={formData.rawNo}>
                            <input name='comment' value={formData.comment ?? ''} onChange={onChange} />
                        </td>
                    </tr>
                </tbody>

            </table>

            <div className='btnsArea'>
                <button className='submitBtn' onClick={onSubmit}>확인</button>
                <button className='cancelBtn' onClick={onCancel}>취소</button>
            </div>
        </div>
    );
}

export default CreateMonitDataStructure;