import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config/constants';
import { Outlet, useOutletContext } from 'react-router';
import './monitDataStructure.scss';
import useAsync from '../../../hooks/useAsync';
import Loading from '../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../../reducer/authentication';

function DetailedDataStructs(props) {
    const { normalRank } = useOutletContext();
    const dispatch = useDispatch();
    const onLogout = () => dispatch(setLogout());
    const { userRank } = useSelector((state) => state.session);
    async function getDataStructures() {
        const response = await axios.post(`${API_URL}/monitoring-manage/getDataStructures`);
        return response.data;
    }
    useEffect(() => {
        if (userRank === normalRank) onLogout();
    }, [userRank, normalRank]);

    const state = useAsync(getDataStructures);
    const { loading, error, data: result } = state;
    if (loading) return <div className='dataStructure' style={{ height: '100%' }}><Loading /></div>
    if (error) {
        console.log(error)
        return <div className='dataStructure'>페이지를 나타낼 수 없습니다.</div>
    }
    if (!result) return null;
    return (
        <div className='dataStructure'>
            <Outlet context={{
                dataStructures: result,
            }} />
        </div>
    );
}

export default DetailedDataStructs;